import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

class Footer extends Component{
    render() {
        return (
        	<React.Fragment>
        		<div className="footer-dark-1 footer-dark-b-1">
        			<Container>
        				<Row>
        					<Col lg={3}>
        						<h3 className="fnt-bold text-uppercase">
                                    {window.Configs.domain}
                                </h3>
                                <p>
                                    Join our exclusive community of like minded people on {window.Configs.domain} and is part of the
                                    &nbsp;<a href="https://www.contrib.com">Contrib</a>&nbsp; 
                                    network.
                                </p>
        					</Col>
        					<Col lg={3}>
        						<h3 className="fnt-bold text-uppercase">
                                    company
                                </h3>
                                <ul className="list-unstyled f-a-links">
                                    <li>
                                        <Link to="/" className="text-capitalize">
                                            Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/about" className="text-capitalize">
                                            about
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/terms" className="text-capitalize">
                                            terms
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy" className="text-capitalize">
                                            privacy
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/cookie" className="text-capitalize">
                                            cookie policy
                                        </Link>
                                    </li>
                                    <li>
                                        <a target="_blank" rel="noopener noreferrer" href={'https://www.domaindirectory.com/policypage/unsubscribe?domain='+window.Configs.domain} className="text-capitalize">
                                            Unsubscribe
                                        </a>
                                    </li>
                                    <li>
                                        <Link to="/apps" className="text-capitalize">
                                            Apps
                                        </Link>
                                    </li>
                                </ul>
        					</Col>
        					<Col lg={3}>
        						<h3 className="fnt-bold text-uppercase">
                                    partners
                                </h3>
                                <p>                        
                                    <a href="https://contrib.io/" target="_blank" rel="noopener noreferrer">
                                    	<img style={{border:"0px"}} src="https://cdn.vnoc.com/banner/banner-ctb-earn-ctb-tokens.png" width="205" height="58" alt="Contrib and Earn CTB Tokens" title="Contrib and Earn CTB Tokens" />
                                    </a>
                                </p>
                                <h3 className="fnt-bold text-uppercase">
                                    Socials
                                </h3>
                                <ul className="list-inline socials-ul">
                                    <li className="list-inline-item">
                                        <a title="twitter" className="icon-button twitter" href="https://twitter.com/contrib ">
                                            <i className="fab fa-twitter"></i>
                                            <span></span>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a title="facebook" className="icon-button facebook" href="https://www.facebook.com/pages/Javapoint/646287392104713 ">
                                            <i className="fab fa-facebook-f"></i>
                                            <span></span>
                                        </a>
                                    </li>                                        
                                    <li className="list-inline-item">
                                        <a title="linkedin" className="icon-button linkedin" href="http://www.linkedin.com/company/javapoint ">
                                            <i className="fab fa-linkedin-in"></i>
                                            <span></span>
                                        </a>
                                    </li>
                                    
                                    <li className="list-inline-item">
                                        <a title="youtube" className="icon-button youtube" href="https://www.youtube.com/user/javapointcom ">
                                            <i className="fab fa-youtube"></i>
                                            <span></span>
                                        </a>
                                    </li>
                                </ul>
        					</Col>
        				</Row>
        			</Container>
        		</div>
        		<div className="footer-dark-2 footer-dark-b-2">
        			<Container>
        				<Row>
        					<Col lg={6} className="f-a-links">
        						&copy; 2020 <Link className="text-capitalize " to="/">{window.Configs.domain.charAt(0).toUpperCase() + window.Configs.domain.slice(1)}</Link>. All Rights Reserved.
        					</Col>
        					<Col lg={6}>
        						<ul className="list-inline text-right f-a-links">
                                    <li className="list-inline-item">
                                        <Link to="/" className="text-capitalize">
                                            <i className="fas fa-home mr-2"></i>
                                            home
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="/about" className="text-capitalize">
                                            <i className="far fa-bookmark mr-2"></i>
                                            About us
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="/terms" className="text-capitalize">
                                            <i className="fas fa-book mr-2"></i>
                                            Terms
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="/privacy" className="text-capitalize">
                                            <i className="fas fa-cube mr-2"></i>
                                            privacy
                                        </Link>
                                    </li>
                                </ul>
								
        					</Col>
        				</Row>
        			</Container>			
        			<div id={'beforeyougo'}>
        			</div>
        		</div>
				
        	</React.Fragment>
        );
    }
}

export default Footer;