import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

class Apps extends Component {
	render(){
		return(
			<React.Fragment>
				<div className='public-pages'>
					<Container>
						<Row>
							<Col lg={12} className="text-center mb-4">
								<h1>Contrib Apps</h1>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-new-contrib-06.png" alt="" />
									</div>
									<p className="desc-app">
										A new business model and collaboration platform for business professionals to earn equity and cash grants with premium digital assets in a flexible and transparent value creation system.
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://contrib.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-socialid4.png" alt="" />
									</div>
									<p className="desc-app">
										SocialID establishes media distribution and identification management services on valuable, engaged networks.  Creating value for companies and engaging the targeted user bases.
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://socialid.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-handyman-1.png" alt="" />
									</div>
									<p className="desc-app">
										Find various experienced, and professional contractors in your area.   Efficiently fulfill your home repair or remodel needs and manage your project details online and anytime.
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://handyman.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-eservices-1-1.png" alt="" />
									</div>
									<p className="desc-app">
										The marketplace for quick, paid opportunities by doing small online tasks ordered daily through a curated network of professionals.
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://eservices.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-referrals-header.png" alt="" />
									</div>
									<p className="desc-app">
										Offers a seamless and easy integration into premium brands while expanding distribution turning contacts and community into commerce.
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://eservices.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-staffing1.png" alt="" />
									</div>
									<p className="desc-app">
										Professional and Curated Talent for short and long term organizational needs and desires.  Remote, value based talent fulfillment with amazing opportunities to earn equity and cash warrants with leading organizations.
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://staffing.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-photostream.png" alt="" />
									</div>
									<p className="desc-app">
										Shoot, Stream, and Monetize. Import your social photos from popular social networks.
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://www.photostream.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-micromarkets.png" alt="" />
									</div>
									<p className="desc-app">
										Post your small service jobs for free. Connect and get paid with thousands of service suppliers from different Micro Markets.
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://micromarkets.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-domaindirectory300x82.png" alt="" />
									</div>
									<p className="desc-app">
										Get the best URL for your website or join partnership opportunities with other related assets.  The right domain name can change your business and life.  We help you find, acquire, sell, build or monetize valuable domain assets. 
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://domaindirectory.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-VentureBook-3.png" alt="" />
									</div>
									<p className="desc-app">
										The leading venture and equity network for digital assets.  Create Venture Suggestions and proposals with leading and exclusive digital assets.
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://venturebook.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-eshares1.png" alt="" />
									</div>
									<p className="desc-app">
										The leading Equity Management and Marketplace for Technology companies in the digital economy. Join the 1,000's of tech focused companies utilizing eShares.com
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://eshares.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/ifund.png" alt="" />
									</div>
									<p className="desc-app">
										Is a software as a service collaborative funding platform for premium digital ventures.  iFund is not a registered broker-dealer but offers opportunities to find and learn about great companies.
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://ifund.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-adrate-3.png" alt="" />
									</div>
									<p className="desc-app">
										Adrate is a unique AI program for native advertising platform with access to over a Billion placements daily.  Join and let AdRate use our ad technology to make you profits.
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://adrate.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-Ipartner1.png" alt="" />
									</div>
									<p className="desc-app">
										Gain access to a curated network of strategic partners and expand your user base with the right partner opportunities.  iPartner helps filter and attract the best partners within our premium venture opportunities. Forming a mutually beneficial and benchmarkable partnership outline.
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://ipartner.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-linked4.png" alt="" />
									</div>
									<p className="desc-app">
										Linking people, skills and opportunities to create the world's largest collaborative commerce and business creation system.
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://linked.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-Applications-7.png" alt="" />
									</div>
									<p className="desc-app">
										Applications is the new way to list and power leading digital assets and make money from your apps.  Sell, Trade and Lease your code using the leading app hosting systems.  Gain access to leading and exclusive scripts that help grow your business.
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://applications.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-CookBoard-2.png" alt="" />
									</div>
									<p className="desc-app">
										Is the #1 Local Food Marketplace where you can buy and sell food items from your local chefs, local cooks in your community. 
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://cookboard.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-BuilderKit-1.png" alt="" />
									</div>
									<p className="desc-app">
										Provides a fast, affordable, high quality business creation and management platform for digital assets. 
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://builderkit.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-liverep2.png" alt="" />
									</div>
									<p className="desc-app">
										Is a technology gamification company building and managing leading brand platforms. Our scalable, domain based platform creates niche, interactive gaming systems that match sponsors with targeted users creating synergy and engagement.
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://liverep.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-ichallenge-2-small.png" alt="" />
									</div>
									<p className="desc-app">
										Is a technology gamification company building and managing leading assets including MyChallenge, CodeChallenge, SportsChallenge and other great niche locations. Our scalable, domain based platform creates niche, interactive gaming systems that match sponsors with targeted users creating synergy and engagement.
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://ichallenge.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/Bidtellect_logoH.png" alt="" />
									</div>
									<p className="desc-app">
										The First Open, Multi-format, Multi-device Native Marketing Platform Connecting Advertisers and Publishers to Deliver Optimized and Insightful Native Advertising at Scale.
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://bidtellect.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-tda.png" alt="" />
									</div>
									<p className="desc-app">
										TalentDirect uses the most updated, modern technology to showcase, schedule and manage talent - making it effortless for any production, film, commercial or promotional event to make their project the best it can be. 
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://talentdirect.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
							<Col lg={3} className="mb-3">
								<div className="box-app p-3">
									<div className="img-app-logo">
										<img className="img-fluid mb-3" src="https://cdn.vnoc.com/logos/logo-Icontent-2.png" alt="" />
									</div>
									<p className="desc-app">
										Create, publish and make your content viral. 
									</p>
									<Button variant="dark" className="btn-block text-uppercase" href="https://icontent.com/">
										<i className="fas fa-search mr-2"></i>
										View
									</Button>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default Apps;