import React, { Component } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FeaturedSiteList from './components/FeaturedSiteList';
import Logo from './components/Logo';

class Home extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="main-container-content" style={{ backgroundImage: `url(${window.Configs.background_image})` }}>
                    <div className="overlay"></div>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mb-5">
                                    <Logo />
                                    <p className="lead-desc"><a href="https://contrib.com/">Proud Member of CONTRIB</a> |<a href="https://vnoc.com">Powered by VNOC</a></p>
                                </div>
                                <Row>
                                    <Col lg={{span: 10, offset: 1}}>
                                        <Row>
                                            <Col lg={4} className="mb-3">
                                                <div className="box-white p-3 text-center rounded">
                                                    <h3 className="mb-0 text-uppercase">
                                                        Buy
                                                    </h3>
                                                    <h4 className="mb-4 lead-small-ttle">
                                                        {window.Configs.domain.charAt(0).toUpperCase() + window.Configs.domain.slice(1)}
                                                    </h4>
                                                    <p>
                                                        <Link to="/buy" className="btn btn-success btn-lg btn-block">
                                                            Make An Offer
                                                        </Link>
                                                    </p>
                                                    <p>Invest in a premium domain<br /> name to be your brand.</p>
                                                </div>
                                            </Col>
                                            <Col lg={4} className="mb-3">
                                                <div className="box-white p-3 text-center rounded">
                                                    <h3 className="mb-0 text-uppercase">
                                                        partner
                                                    </h3>
                                                    <h4 className="mb-4 lead-small-ttle">
                                                        {window.Configs.domain.charAt(0).toUpperCase() + window.Configs.domain.slice(1)}
                                                    </h4>
                                                    <p>
                                                        <Link to="/partner" className="btn btn-success btn-lg btn-block">
                                                            Submit Partnership
                                                        </Link>
                                                    </p>
                                                    <p>
                                                        Access this premium domain name 
                                                        without the premium price tag.
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col lg={4} className="mb-3">
                                                <div className="box-white p-3 text-center rounded">
                                                    <h3 className="mb-0 text-uppercase">
                                                        JOIN
                                                    </h3>
                                                    <h4 className="mb-4 lead-small-ttle">
                                                        {window.Configs.domain.charAt(0).toUpperCase() + window.Configs.domain.slice(1)} Community
                                                    </h4>
                                                    <p>
                                                        <a target="_blank" rel="noopener noreferrer" href={'https://www.contrib.com/signup/firststep?domain='+window.Configs.domain} className="btn btn-success btn-lg btn-block">
                                                            Join Now
                                                        </a>
                                                    </p>
                                                    <p>Join our community of 150,000<br /> {window.Configs.domain.charAt(0).toUpperCase() + window.Configs.domain.slice(1)} members over at Contrib.</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="section-2">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <h1 className="fnt-600">
                                    How Buying or Partnering with {window.Configs.domain.charAt(0).toUpperCase() + window.Configs.domain.slice(1)} Works
                                </h1>
                                <br />
                                <br />
                                <br />
                                <Row>
                                    <Col lg={4} className="text-center mb-3">
                                        <img className="img-fluid how-icon" src="https://cdn.vnoc.com/icons/icon-diamond.png" alt="Make an offer" />
                                        <h3>Make An Offer</h3>
                                        <p>
                                            Find a domain and decide to buy,lease or rent it. Submit your best offer for the owner's consideration.
                                        </p>
                                    </Col>
                                    <Col lg={4} className="text-center mb-3">
                                        <img className="img-fluid how-icon" src="https://cdn.vnoc.com/icons/icon-briefcase.png" alt="Negotiate the price" />
                                        <h3>Negotiate the price</h3>
                                        <p>
                                            Your offer may be accepted, countered or declined. Negotiations can take as little as one day or up to a few weeks.
                                        </p>
                                    </Col>
                                    <Col lg={4} className="text-center mb-3">
                                        <img className="img-fluid how-icon" src="https://cdn.vnoc.com/icons/icon-handshake.png" alt="Make a deal" />
                                        <h3>Make a deal</h3>
                                        <p>
                                            Once you have agreed on the price, We will facilitate the contract, payment and transfer or use of the domain name. 
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="section-3">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <h2 className="text-center mb-5">{window.Configs.domain.charAt(0).toUpperCase() + window.Configs.domain.slice(1)} Partners</h2>
                                <Row>
                                    <Col lg={4} className="mb-3"></Col>
                                    <Col lg={4} className="mb-3"></Col>
                                    <Col lg={4} className="mb-3">
                                        <Card>
                                            <Card.Header>Latest Contribution</Card.Header>
                                            <Card.Body className="card-overflow-cus">
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <div className="media">
                                                            <img src="https://www.contrib.com/img/timthumb.php?src=https://www.contrib.com/uploads/profile/1586292067fPV09.jpg&w=115&h=115" className="mr-3 img-thumbnail-cust" alt="" />
                                                            <div className="media-body">
                                                                <h5 className="mt-0">Techy Micro</h5>
                                                                <p className="small mb-0">
                                                                    Has contributed marketing on &nbsp; <a href="/">staffingnetworks.com</a>
                                                                </p>
                                                                <p className="text-danger fnt-600">
                                                                    20 pts
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="media">
                                                            <img src="https://www.contrib.com/img/timthumb.php?src=https://www.contrib.com/img/avatar0.jpg&w=115&h=115" className="mr-3 img-thumbnail-cust" alt="" />
                                                            <div className="media-body">
                                                                <h5 className="mt-0">Techy Micro</h5>
                                                                <p className="small mb-0">
                                                                    Has contributed marketing on &nbsp; <a href="/">staffingnetworks.com</a>
                                                                </p>
                                                                <p className="text-danger fnt-600">
                                                                    20 pts
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="media">
                                                            <img src="https://www.contrib.com/img/timthumb.php?src=https://www.contrib.com/img/avatar0.jpg&w=115&h=115" className="mr-3 img-thumbnail-cust" alt="" />
                                                            <div className="media-body">
                                                                <h5 className="mt-0">Techy Micro</h5>
                                                                <p className="small mb-0">
                                                                    Has contributed marketing on &nbsp; <a href="/">staffingnetworks.com</a>
                                                                </p>
                                                                <p className="text-danger fnt-600">
                                                                    20 pts
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="media">
                                                            <img src="https://www.contrib.com/img/timthumb.php?src=https://www.contrib.com/img/avatar0.jpg&w=115&h=115" className="mr-3 img-thumbnail-cust" alt="" />
                                                            <div className="media-body">
                                                                <h5 className="mt-0">Techy Micro</h5>
                                                                <p className="small mb-0">
                                                                    Has contributed marketing on &nbsp; <a href="/">staffingnetworks.com</a>
                                                                </p>
                                                                <p className="text-danger fnt-600">
                                                                    20 pts
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="media">
                                                            <img src="https://www.contrib.com/img/timthumb.php?src=https://www.contrib.com/img/avatar0.jpg&w=115&h=115" className="mr-3 img-thumbnail-cust" alt="" />
                                                            <div className="media-body">
                                                                <h5 className="mt-0">Techy Micro</h5>
                                                                <p className="small mb-0">
                                                                    Has contributed marketing on &nbsp; <a href="/">staffingnetworks.com</a>
                                                                </p>
                                                                <p className="text-danger fnt-600">
                                                                    20 pts
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="media">
                                                            <img src="https://www.contrib.com/img/timthumb.php?src=https://www.contrib.com/img/avatar0.jpg&w=115&h=115" className="mr-3 img-thumbnail-cust" alt="" />
                                                            <div className="media-body">
                                                                <h5 className="mt-0">Techy Micro</h5>
                                                                <p className="small mb-0">
                                                                    Has contributed marketing on &nbsp; <a href="/">staffingnetworks.com</a>
                                                                </p>
                                                                <p className="text-danger fnt-600">
                                                                    20 pts
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="media">
                                                            <img src="https://www.contrib.com/img/timthumb.php?src=https://www.contrib.com/img/avatar0.jpg&w=115&h=115" className="mr-3 img-thumbnail-cust" alt="" />
                                                            <div className="media-body">
                                                                <h5 className="mt-0">Techy Micro</h5>
                                                                <p className="small mb-0">
                                                                    Has contributed marketing on &nbsp; <a href="/">staffingnetworks.com</a>
                                                                </p>
                                                                <p className="text-danger fnt-600">
                                                                    20 pts
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="section-4">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <h1 className="text-center fnt-700">OUR TOP BRANDS</h1>
                                <FeaturedSiteList />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Home;