import React from 'react'

function Logo() {
 
    if(window.Configs.logo===''){
		return <h1>{window.Configs.domain.charAt(0).toUpperCase() + window.Configs.domain.slice(1)}</h1>
	}else{
		return <img className="img-fluid logo-mh" src={window.Configs.logo} alt="" />
	}
  
}

export default Logo
