import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import LoadingState from './LoadingState';
import axios from 'axios'
const initialState = {
	domain:window.Configs.domain,
	step: 1,

	//Step 1
	partnershiptype:"",
	fname:"",
	lname:"",
	email:"",
	password:"",
	cpassword:"",
	message:"",

	//Step 2
	website: "",
	phone: "",
	country: window.Configs.countriesarray[0].country_id,
	country_text: '',
	city: "",

	//Step 3
	companytitle: "",
	companydesc: "",
	companyimg: "",
	companylink: "",

	//Error Msg Step1
	partnershiptypeError:"",
	fnameError:"",
	lnameError:"",
	emailError:"",
	passwordError:"",
	cpasswordError:"",
	messageError:"",

	//Error Msg Step2
	websiteError: "",
	phoneError: "",
	countryError: "",
	cityError: "",

	//Error Msg Step3
	companytitleError: "",
	companydescError: "",
	companyimgError: "",
	companylinkError: "",

	isLoading: false,
	newSuccess: true,
	newMsgSuccess: false,
	emailcode:''
};

class PartnerForm extends Component{

	state = initialState;
	
	handleChangeCountry = event => {
		this.setState({
			country: event.target.value,
		});
		this.setState({
			country_text: event.target.options[event.target.selectedIndex].text
		});
	};
	
	handleChange = event => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};


	validate = () => {
		const {step} = this.state;
		//Step1
	    let partnershiptypeError = "";
		let fnameError = "";
		let lnameError = "";
		let emailError = "";
		let passwordError = "";
		let cpasswordError = "";
		let messageError = "";

		//Step 2
		let websiteError = "";
		let phoneError = "";
		let countryError = "";
		let cityError = "";

		//Step 3
		let companytitleError = "";
		let companydescError = "";

		var httpsRegex = "^((https)?://)"
		+ "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?"
		+ "(([0-9]{1,3}\\.){3}[0-9]{1,3}"
		+ "|"
		+ "([0-9a-z_!~*'()-]+\\.)*"
		+ "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\\."
		+ "[a-z]{2,6})"
		+ "(:[0-9]{1,4})?"
		+ "((/?)|"
		+ "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";
		
		var httpsPattern=new RegExp(httpsRegex);
		
	    if(step === 1){
	    	if(!this.state.partnershiptype){
				partnershiptypeError = "Partnership Type is required.";
		    }
		    
		    if (!this.state.fname) {
		    	fnameError = "First name is required.";
		    }
		    if (!this.state.lname) {
		    	lnameError = "Last name is required.";
		    }
		    if (!this.state.email) {
		    	emailError = "Email is required";
		    }else if(!this.state.email.includes("@")){
		    	emailError = "Invalid Email";
		    }
		    if (!this.state.password) {
		    	passwordError = "Password is required.";
		    }
		    if (!this.state.cpassword) {
		    	cpasswordError = "Confirm password is required.";
		    }
		    else if(this.state.cpassword !== this.state.password){
		    	cpasswordError = "Confirm password did not match.";
		    }
		    if (!this.state.message) {
		    	messageError = "Message is required.";
		    }
		    if ( 
		    	partnershiptypeError || 
		    	fnameError || 
		    	lnameError || 
		    	emailError || 
		    	passwordError || 
		    	cpasswordError || 
		    	messageError 
		    ){
		    	this.setState({ 
		    		partnershiptypeError, 
		    		fnameError, 
		    		lnameError, 
		    		emailError, 
		    		passwordError, 
		    		cpasswordError, 
		    		messageError
		    	});
		    	return false;
		    }
			this.setState({
				step: step + 1
			});		 
		    return true;
	    }
	    if(step === 2){
	    	if (!this.state.website) {
		    	websiteError = "Website is required.";
		    }
			
			if(!httpsPattern.test(this.state.website)){
				websiteError = "Invalid website.";
			}
  
	    	if (!this.state.phone) {
		    	phoneError = "Phone is required.";
		    }
			
			if(!this.state.phone.match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\\s\\./0-9]*$/g)){
				phoneError = "Invalid Phone.";
			}
			
	    	if (!this.state.country) {
		    	countryError = "Country is required.";
		    }
	    	if (!this.state.city) {
		    	cityError = "City is required.";
		    }
		    if ( 
		    	websiteError || 
		    	phoneError || 
		    	countryError || 
		    	cityError
		    ){
		    	this.setState({ 
		    		websiteError, 
		    		phoneError, 
		    		countryError, 
		    		cityError
		    	});
		    	return false;
		    }
			this.setState({
				step: step + 1
			});
			return true;		 
	    }
	    if(step === 3){
			if (!this.state.companytitle) {
		    	companytitleError = "Company Title is required.";
		    }
			if (!this.state.companydesc) {
		    	companydescError = "Company description is required.";
		    }
			
		    if ( 
		    	companytitleError || 
		    	companydescError 
		    ){
		    	this.setState({ 
		    		companytitleError, 
		    		companydescError
		    	});
		    	return false;
		    }
			this.setState({
				step: step + 1
			});		 
			return true;	  
	    }

	    return true;
	};

	onChangeFrom(){
		this.props.onchangeform(this.state.newSuccess,this.state.newMsgSuccess,this.state.emailcode);
	}

	handleSubmit = event => {
		event.preventDefault();
		const isValid = this.validate();
		
		if(isValid && this.state.step===3){
			
			//this.loadingHandle();
			this.setState({isLoading:true});
			
			const params = new URLSearchParams();
			params.append('field', 'EmailAddress');
			params.append('value', this.state.email);
			axios.post('https://www.contrib.com/signup/checkexist', params)
				.then(response => {
					this.setState({isLoading:false});
					if(response.data.status){
						this.setState({
							step: 1
						});
						alert('Email already exists');
						return false;
					}else{
						const params = new URLSearchParams();
						params.append('partnertype', this.state.partnershiptype);
						params.append('domain', this.state.domain);
						params.append('firstname', this.state.fname);
						params.append('lastname', this.state.lname);
						params.append('email', this.state.email);
						params.append('country_id', this.state.country);
						params.append('country', this.state.country_text);
						params.append('city', this.state.city);
						params.append('password', this.state.password);
						params.append('phone', this.state.phone);
						params.append('message', this.state.message);
						params.append('company', this.state.companytitle);
						params.append('companydesc', this.state.companydesc);
						
						this.setState({isLoading:true});
						axios.post('https://www.contrib.com/forms/save_partner', params)
							.then(response => {
								console.log(response);
								console.log(response.data.email);
								this.setState({isLoading:false});
								this.setState({emailcode:response.data.email});
								
								const params = new URLSearchParams();
								params.append('form_type', 'VNOC Partnership');
								params.append('partner_type', this.state.partnershiptype);
								params.append('domain', this.state.domain);
								params.append('firstName', this.state.firstName);
								params.append('lastName', this.state.lastName);
								params.append('email', this.state.email);
								params.append('country', this.state.country)
								params.append('city', this.state.city);
								params.append('password', this.state.password);
								params.append('phone', this.state.phone);
								params.append('message', this.state.message);
								params.append('title', this.state.companytitle);
								this.setState({isLoading:true});
								axios.post('https://manage.vnoc.com/salesforce/addlead', params)
									.then(response => {
										this.setState({isLoading:false});
										this.onChangeFrom();
									});
								
							});
							
						
					}
				})
				.catch(error => {
					console.log(error)
				})
		}
	}

	prevStep = () =>{
		const { step } = this.state;

		this.setState({
			step: step - 1
		});
	}

	showStep = () => {
		const {step} = this.state;
		if(step === 1){
			return (
				<React.Fragment>
					<h2 className="text-center mt-3">Partner with {window.Configs.domain.charAt(0).toUpperCase() + window.Configs.domain.slice(1)} today</h2>
					<p className="text-center mb-4">Create your Profile to make a partnership</p>
					
					<Row>
						<Col lg={12} className={this.state.partnershiptypeError ? ('was-validated') : null}>
							<Form.Group>
								<Form.Label>Partnership Type <span className="text-danger">*</span></Form.Label>
								<Form.Control 
									as="select" 
									className="custom-select"
									name="partnershiptype"
									onChange={this.handleChange}
									value={this.state.partnershiptype}
									required
								>
									<option></option>
									<option value="Sponsorship Marketing Partnerships">Sponsor Marketing Partnerships</option>
									<option value="Distribution Marketing Partnerships">Distribution Marketing Partnerships</option>
									<option value="Affiliate Marketing Partnerships">Affiliate Marketing Partnerships</option>
									<option value="Added Value Marketing Partnerships">Added Value Marketing Partnerships</option>
								</Form.Control>
								{
									this.state.partnershiptypeError ? (
										<div className="d-block text-danger small mt-2">
											{this.state.partnershiptypeError}
										</div>
									) : null
								}
							</Form.Group>
						</Col>
						<Col lg={6} className={this.state.fnameError ? ('was-validated') : null}>
							<Form.Group>
								<Form.Label>First Name <span className="text-danger">*</span></Form.Label>
								<Form.Control 
									type="text"
									name="fname"
									onChange={this.handleChange}
									value={this.state.fname}

									required
								/>
								{
									this.state.fnameError ? (
										<div className="d-block text-danger small mt-2">
											{this.state.fnameError}
										</div>
									) : null
								}
							</Form.Group>
						</Col>
						<Col lg={6} className={this.state.lnameError ? ('was-validated') : null}>
							<Form.Group>
								<Form.Label>Last Name <span className="text-danger">*</span></Form.Label>
								<Form.Control 
									type="text"
									name="lname"
									onChange={this.handleChange}
									value={this.state.lname}

									required
								/>
								{
									this.state.lnameError ? (
										<div className="d-block text-danger small mt-2">
											{this.state.lnameError}
										</div>
									) : null
								}
							</Form.Group>
						</Col>
						<Col lg={12} className={this.state.emailError ? ('was-validated') : null}>
							<Form.Group>
								<Form.Label>Email Address <span className="text-danger">*</span></Form.Label>
								<Form.Control 
									type="text" 
									name="email"
									onChange={this.handleChange}
									value={this.state.email}
									required
								/>
								{
									this.state.emailError ? (
										<div className="d-block text-danger small mt-2">
											{this.state.emailError}
										</div>
									) : null
								}
							</Form.Group>
						</Col>
						<Col lg={6} className={this.state.passwordError ? ('was-validated') : null}>
							<Form.Group>
								<Form.Label>Password <span className="text-danger">*</span></Form.Label>
								<Form.Control 
									type="password" 
									name="password"
									onChange={this.handleChange}
									value={this.state.password}
									required
								/>
								{
									this.state.passwordError ? (
										<div className="d-block text-danger small mt-2">
											{this.state.passwordError}
										</div>
									) : null
								}
							</Form.Group>
						</Col>
						<Col lg={6} className={this.state.cpasswordError ? ('was-validated') : null}>
							<Form.Group>
								<Form.Label>Confirm Password <span className="text-danger">*</span></Form.Label>
								<Form.Control 
									type="password" 
									name="cpassword"
									onChange={this.handleChange}
									value={this.state.cpassword}
									required
								/>
								{
									this.state.cpasswordError ? (
										<div className="d-block text-danger small mt-2">
											{this.state.cpasswordError}
										</div>
									) : null
								}
							</Form.Group>
						</Col>
						<Col lg={12} className={this.state.messageError ? ('was-validated') : null}>
							<Form.Group>
								<Form.Label>Message <span className="text-danger">*</span></Form.Label>
								<Form.Control 
									as="textarea" 
									rows="3" 
									name="message"
									onChange={this.handleChange}
									value={this.state.message}
									required
								/>
								{
									this.state.messageError ? (
										<div className="d-block text-danger small mt-2">
											{this.state.messageError}
										</div>
									) : null
								}
							</Form.Group>
						</Col>
						<Col lg={12}>
							<Form.Group className="mt-3">
								<Button variant="primary" className="btn-block py-3" onClick={this.handleSubmit}>
									NEXT
								</Button>
							</Form.Group>
						</Col>
					</Row>
				</React.Fragment>
			);
		}
		if(step === 2){
			return (
				<React.Fragment>
					<Row>
						<Col lg={12} className={this.state.websiteError ? ('was-validated') : null}>
							<Form.Group>
								<Form.Label>Website URL <span className="text-danger">*</span></Form.Label>
								<Form.Control 
									type="text"
									name="website"
									onChange={this.handleChange}
									value={this.state.website}
									required
								/>
								{
									this.state.websiteError ? (
										<div className="d-block text-danger small mt-2">
											{this.state.websiteError}
										</div>
									) : null
								}
							</Form.Group>
						</Col>
						<Col lg={12} className={this.state.phoneError ? ('was-validated') : null}>
							<Form.Group>
								<Form.Label>Phone <span className="text-danger">*</span></Form.Label>
								<Form.Control 
									type="text"
									name="phone"
									onChange={this.handleChange}
									value={this.state.phone}

									required
								/>
								{
									this.state.phoneError ? (
										<div className="d-block text-danger small mt-2">
											{this.state.phoneError}
										</div>
									) : null
								}
							</Form.Group>
						</Col>
						<Col lg={6} className={this.state.countryError ? ('was-validated') : null}>
							<Form.Group>
								<Form.Label>Country <span className="text-danger">*</span></Form.Label>
								<Form.Control 
									as="select"
									type="text"
									name="country"
									onChange={this.handleChangeCountry}
									value={this.state.country}
									className="custom-select"
									required
								>
									{window.Configs.countriesarray.map(country=><option key={country.country_id} value={country.country_id}>{country.name}</option>)}
								</Form.Control>
								{
									this.state.countryError ? (
										<div className="d-block text-danger small mt-2">
											{this.state.countryError}
										</div>
									) : null
								}
							</Form.Group>
						</Col>
						<Col lg={6} className={this.state.cityError ? ('was-validated') : null}>
							<Form.Group>
								<Form.Label>City <span className="text-danger">*</span></Form.Label>
								<Form.Control 
									type="text"
									name="city"
									onChange={this.handleChange}
									value={this.state.city}

									required
								/>
								{
									this.state.cityError ? (
										<div className="d-block text-danger small mt-2">
											{this.state.cityError}
										</div>
									) : null
								}
							</Form.Group>
						</Col>
						<Col lg={12}>
							<Row>
								<Col lg={6}>
									<Form.Group className="mt-3">
										<Button variant="secondary" className="btn-block py-3" onClick={this.prevStep}>
											BACK
										</Button>
									</Form.Group>
								</Col>
								<Col lg={6} className="text-lg-right">
									<Form.Group className="mt-3">
										<Button variant="primary" className="btn-block py-3" onClick={this.handleSubmit}>
											NEXT
										</Button>
									</Form.Group>
								</Col>
							</Row>
						</Col>
					</Row>
				</React.Fragment>
			);
		}
		if(step === 3){
			return (
				<React.Fragment>
					<Row>
						<Col lg={12} className={this.state.companytitleError ? ('was-validated') : null}>
							<Form.Group>
								<Form.Label>Company Title <span className="text-danger">*</span></Form.Label>
								<Form.Control 
									type="text"
									name="companytitle"
									onChange={this.handleChange}
									value={this.state.companytitle}
									required
								/>
								{
									this.state.companytitleError ? (
										<div className="d-block text-danger small mt-2">
											{this.state.companytitleError}
										</div>
									) : null
								}
							</Form.Group>
						</Col>
						<Col lg={12} className={this.state.companydescError ? ('was-validated') : null}>
							<Form.Group>
								<Form.Label>Company Description <span className="text-danger">*</span></Form.Label>
								<Form.Control 
									as="textarea"
									name="companydesc"
									onChange={this.handleChange}
									value={this.state.companydesc}

									required
								/>
								{
									this.state.companydescError ? (
										<div className="d-block text-danger small mt-2">
											{this.state.companydescError}
										</div>
									) : null
								}
							</Form.Group>
						</Col>
						<Col lg={6}>
							<Form.Group>
								<Form.Label>Company Image Url Link </Form.Label>
								<Form.Control 
									type="text"
									name="companyimg"
									onChange={this.handleChange}
									value={this.state.companyimg}
									
								/>
							</Form.Group>
						</Col>
						<Col lg={6}>
							<Form.Group>
								<Form.Label>Company Url Link </Form.Label>
								<Form.Control 
									type="text"
									name="companylink"
									onChange={this.handleChange}
									value={this.state.companylink}
								/>
							</Form.Group>
						</Col>
						<Col lg={12}>
							<Row>
								<Col lg={6}>
									<Form.Group className="mt-3">
										<Button variant="secondary" className="btn-block py-3" onClick={this.prevStep}>
											BACK
										</Button>
									</Form.Group>
								</Col>
								<Col lg={6} className="text-lg-right">
									<Form.Group className="mt-3">
										<Button variant="primary" className="btn-block py-3" onClick={this.handleSubmit}>
											SUBMIT
										</Button>
									</Form.Group>
								</Col>
							</Row>
						</Col>
					</Row>
				</React.Fragment>
			);
		}
	}

	render(){
		return(
			<React.Fragment>
				{this.showStep()}
				
				<Row>
					<Col lg={12}>
						<Link to="/buy" className="text-center d-block">Or you can buy this domain!</Link>
					</Col>
				</Row>
				
				{this.state.isLoading ? <LoadingState />: null}
			</React.Fragment>
		);
	}
}

export default PartnerForm;