import React, { Component } from 'react';
import { Container, Row, Col, Jumbotron } from 'react-bootstrap';
import Logo from './components/Logo';
class About extends Component{
	render(){
		return(
			<React.Fragment>
				<div className="public-pages">
					<Container>
						<Row>
							<Col lg={12}>
								<div className="text-center mb-5">
									<Logo />
									<p className="lead-desc">
										<a href="https://contrib.com/">Proud Member of CONTRIB</a> | 
										<a href="https://vnoc.com">Powered by VNOC</a>
									</p>
								</div>
								<Row>
									<Col lg={{span: 10, offset: 1}} className="text-center">
										<Jumbotron>
											<h2 className="text-center">About Us</h2>
											<p>
												{window.Configs.domain.charAt(0).toUpperCase() + window.Configs.domain.slice(1)} is currently part of the Contrib platform
											</p>
											<p className="mb-5">
												Contrib is a transparent Platform-as-a-Service specifically designed to utilize a sustainable and socially responsible business network model that turns valuable domain assets into successful operating companies.
											</p>
											<h3 className="text-center vmsion">Our Vision</h3>
											<p className="mb-5">
												We envision people around the world with complementary skills, passion, time and resources coworking online building sustainable electronic businesses, part-time. Our focus will continue to support {window.Configs.domain.charAt(0).toUpperCase() + window.Configs.domain.slice(1)} members with a system to grow and learn new skills, help build a company from scratch using the worlds best assets, and to contribute with other great, like-minded and talented people.
											</p>
											<h3 className="text-center vmsion d-none">Our Story</h3>
											<p className="d-none">
												Contrib was created out of necessity to build, manage and monetize thousands of premium Urls like {window.Configs.domain.charAt(0).toUpperCase() + window.Configs.domain.slice(1)} acquired over the 18 years of its parent company, GV, llc. Premium assets such as Streaming.com, Staffing.com, Linked and others were losing opportunity value so we built a model and technology to leverage the opportunities and offer it to our visitors.
											</p>
										</Jumbotron>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default About;